//INIT VALUE
$init-margin:0;
$init-padding:0;
$init-color:#ffffff;
$init-color-transparent:transparent;
$init-color-black:#000000;
$init-transition:ease-in-out 0.25s;
$init-border-raduis:1rem;
$init-color-primary-sun:#5D9CE6;
$init-color-primary-snow:#ffffff;
//Search beautiful grey color
$init-color-primary-clouds:#b4b4b4;
$init-color-primary-rain:#1a1a1a;



//Border-radius
$small-border-raduis:.5rem;
$large-border-raduis:0.75rem;
$border-raduis-card:2rem;
$border-raduis-full:50%;



//COLOR
$bkg-color-blue-very-light:#e4f1ff99;
$bkg-color-blue-light:#b4d6fd;
$bkg-color-grey:#B1B1B1;

//--BKG

$padding-card:2rem;
$margin-text:2rem;
$width-height-p:35px;
$width-height-img:40px;
$margin-bottom-large:3rem;