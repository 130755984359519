.infos-left {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 15px;
  
    img {
      float: left;
      width: 15px;
    }
  
    p {
      color: white;
    }
  
    div {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
  
      &:nth-child(2n) {
        justify-self: end;
      }
    }
  }
  
  
  .temperature {
    color: white;
    text-align: center;
  
    .number {
      font-size: 5rem;
      text-align: center;
    }
  
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  
  }
  
  .img-temp{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }