// Base
@import "./base/base";
@import "./base/typography";

// Utils
@import "./utils/variables";
@import "./utils/functions";
@import "./utils/mixins";
@import "./utils/extensions";

// Components
@import "./components/dayWeather";
@import "./components/infosCard";
@import "./components/meteo";

// Layouts
// @import "./layouts/header";

// Pages
@import "./pages/index";
@import "./pages/pages404";


* {
    margin: $init-margin;
    padding: $init-padding;
    box-sizing: border-box;
    font-family: 'Poppins', 'sans-serif';
}

body{
    background-color: $bkg-color-blue-light;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5vh 0;
}

.container {
    display: grid;
    grid-template-rows: 1fr 5fr;
    align-items: start;
    width: 95vw;
    height: 100%;
    background: $init-color-primary-sun;
    border-radius: $border-raduis-card;
    min-height: 35vh;
    background-size: cover;
    background-repeat: no-repeat;
}

p, a{
    font-size: 14px;
    font-weight: 400;
}

a{
    text-decoration: none;
    color: black;

    &:hover{
        text-decoration: underline;
    }
}

section{
    padding: $padding-card;
}

.right{
    background-color: $bkg-color-blue-very-light;
    height: 100%;
    border-radius: $border-raduis-card;
    width: inherit;
}

.left{
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: inherit;
}

@media (min-width: 768px) {
    .container{
        grid-template-columns: 1fr 3fr;
        grid-template-rows: none;
    }
    .left, .right{
        width: auto;
    }

}